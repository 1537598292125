import * as React from 'react';
import { useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { NavBar } from '@app/components/Header/NavBar';
import { items } from '@app/components/Header/Menu';
import { InfoSection } from '@app/components/Header/InfoSection';
import { BurgerMenu } from '@app/components/BurgerMenu/BurgerMenu';

import '@app/scss/components/header.scss';

const home = '/';

interface OwnProps {
	minimized?: boolean;
}

export const Header: React.FC<OwnProps> = (props: OwnProps) => {
	const location = useLocation();
	const isMain = !props.minimized && location.pathname === home;

	return (
		<header className={clsx('site-header', isMain && 'is-main-page')}>
			<InfoSection scrollToSubscribe={isMain} />
			<NavBar items={items} />
			<BurgerMenu
				items={items}
				scrollToSubscribe={isMain}
			/>
			{
				isMain ? (
					<div className="col-12 col-xs-7 col-lg-9 site-header__title">
						<div className="font-family_primary condensed font-weight_semi-bold text-size_extra-large">
							<b>The world's best destinations with the&nbsp;</b>
							<i className="font-family_decorative font-weight_regular">World's best </i>
							<b>travel agency</b>
						</div>
					</div>
				) : null
			}
		</header>
	);
};
