import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
	Field,
	FieldProps,
	Form,
	Formik,
	useFormikContext,
} from 'formik';

import { Nullable } from '@app/objects/Utility';
import { ActionMode, Button, ButtonVariant } from '@app/components/Button/Button';
import { Option } from '@app/components/Selects/Option';

import { Select } from '@app/components/Selects/Select';
import { useSearchPackagesURLManager } from '@app/hooks/useSearchPackagesURLManager';

import { Icon } from '@app/image/icon';

import { PackagesFilterOption } from '@app/objects/CruisePackages';
import { PackagesFilterKeys } from '@app/objects/Filters/Packages';
import { SEARCH_TEXT_PACKAGE_TAB } from '@app/pages/MainPage/SearchCard/text';
import { FilterURLTraverser } from '@app/services/searchPackages/FilterURLTraverser';
import { DispatchType, RootStore } from '@app/store';
import { getActionCreators } from '@app/store/MainPage/ListActions';

import '@app/scss/layout.scss';

interface FormValues {
	[PackagesFilterKeys.DATE]: Nullable<number>;
	[PackagesFilterKeys.CRUISELINE]: Nullable<string>;
	[PackagesFilterKeys.DESTINATIONREGION]: Nullable<string>,
}

const initialValues: FormValues = {
	[PackagesFilterKeys.DATE]: null,
	[PackagesFilterKeys.CRUISELINE]: null,
	[PackagesFilterKeys.DESTINATIONREGION]: null,
};

interface IWithName {
	name: string;
}

function sorted<T extends IWithName>(list?: Array<T>) {
	return list?.sort((a, b) => {
		if (a.name > b.name) return 1;

		return -1;
	});
}

const Content = () => {
	const { values } = useFormikContext<FormValues>();
	const dispatch = useDispatch<DispatchType<'packagesFilters'>>();
	const store = useSelector((state: RootStore) => state.packagesFilters);
	const manager = useSearchPackagesURLManager();

	React.useEffect(() => {
		const traverser = new FilterURLTraverser();
		const oldQuery = manager.toString(traverser);

		manager.set(PackagesFilterKeys.CRUISELINE, values[PackagesFilterKeys.CRUISELINE] ? [values[PackagesFilterKeys.CRUISELINE]] : []);
		manager.set(PackagesFilterKeys.DATE, values[PackagesFilterKeys.DATE] ? [values[PackagesFilterKeys.DATE]] : []);

		const destinations = store.item?.[PackagesFilterKeys.DESTINATION]
			.filter((item: PackagesFilterOption<string, string>) => values[PackagesFilterKeys.DESTINATIONREGION] === item.parentId)
			.map((item: PackagesFilterOption<string, string>) => item.id) ?? [];
		manager.set(PackagesFilterKeys.DESTINATION, destinations);

		const query = manager.toString(traverser);
		if (!store.item || query !== oldQuery) {
			const factory = getActionCreators(`api/search/v1/packages/filters?${query}`, 'packagesFilters');
			dispatch(factory.request());
		}
	}, [values, store.item?.[PackagesFilterKeys.DESTINATION], dispatch]);

	return (
		<>
			<Field name={PackagesFilterKeys.DESTINATIONREGION}>
				{({ field, form }: FieldProps<string, FormValues>) => (
					<div className="col-12 col-xl-4">
						<Select
							placeholder="Destination/River"
							Icon={Icon.Location}
							loading={store.loading}
							value={field.value}
							onChange={(value?: string) => {
								form.setFieldValue(PackagesFilterKeys.DESTINATIONREGION, value);
							}}
						>
							{
								sorted(store.item?.[PackagesFilterKeys.DESTINATIONREGION])
									?.filter((item: PackagesFilterOption<string>) => item.name !== null)
									?.map((item: PackagesFilterOption<string>) => (
										<Option key={item.id} value={item.id}>
											{item.name}
										</Option>
									))
							}
						</Select>
					</div>
				)}
			</Field>
			<Field name={PackagesFilterKeys.CRUISELINE}>
				{({ field, form }: FieldProps<string, FormValues>) => (
					<div className="col-12 col-xl-4">
						<Select
							placeholder="Cruise Line/Tour Operator"
							Icon={Icon.Helm}
							loading={store.loading}
							value={field.value}
							onChange={(value?: string) => {
								form.setFieldValue(PackagesFilterKeys.CRUISELINE, value);
							}}
						>
							{
								store.item?.cruiseLine.map((item: PackagesFilterOption<string>) => (
									<Option key={item.id + 1} value={item.id}>
										{item.name}
									</Option>
								))
							}
						</Select>
					</div>
				)}
			</Field>
			<Field name={PackagesFilterKeys.DATE}>
				{({ field, form }: FieldProps<number, FormValues>) => (
					<div className="col-12 col-xl-4">
						<Select
							placeholder="Travel Dates"
							Icon={Icon.Calendar}
							loading={store.loading}
							value={field.value}
							onChange={(value?: number) => {
								form.setFieldValue(PackagesFilterKeys.DATE, value);
							}}
						>
							{
								store.item?.date.map((item: PackagesFilterOption<number>) => (
									<Option key={item.id} value={item.id}>
										{item.name}
									</Option>
								))
							}
						</Select>
					</div>
				)}
			</Field>
		</>
	);
};

export const SearchPackages: React.FC = () => {
	const manager = useSearchPackagesURLManager();
	const navigate = useNavigate();

	return (
		<Formik<FormValues>
			initialValues={initialValues}
			onSubmit={() => navigate(`/search?${manager.toString()}`)}
		>
			{(formikBag) => (
				<Form id="package-search-form">
					<div className="layout-container">
						<div className="col-12 col-lg-6 col-xl-12">
							<div className="layout-container">
								<div className="text-size_large col-12 col-xl-6">
									<div className="font-family_primary font-weight_semi-bold">
										SAVE $1,000s
									</div>
									<div>
										<i className="font-family_decorative font-weight_regular">
											with Online Vacation Center's Exclusives
										</i>
									</div>
								</div>
								<div className="text-size_extra-small font-weight_regular col-12 col-xl-6" style={{ lineHeight: '21px' }}>
									{SEARCH_TEXT_PACKAGE_TAB}
								</div>
							</div>
						</div>

						<div className="col-12 col-lg-6 col-xl-12">
							<div className="layout-container content-halign_center">
								<Content />

								<div className="button-container col-12">
									<div className="layout-container reverse content-valign_top content-halign_center">
										<div className="col-12 col-xl-4">
											<Button
												className="uppercase"
												variant={ButtonVariant.Filled}
												onClick={formikBag.handleSubmit}
											>
												Search Packages
											</Button>
										</div>
										<div className="col-12 col-xl-4 btn-reset">
											<Button
												variant={ButtonVariant.Underlined}
												className="uppercase"
												action={ActionMode.Button}
												onClick={formikBag.resetForm}
											>
												Reset all
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	);
};
