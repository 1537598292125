import { TIcon } from '@app/objects/Utility';

/* Figma Icons */
import { ReactComponent as Location } from '@image/icon/location.svg';
import { ReactComponent as Anchor } from '@image/icon/anchor.svg';
import { ReactComponent as Helm } from '@image/icon/helm.svg';
import { ReactComponent as Ship } from '@image/icon/ship.svg';
import { ReactComponent as Calendar } from '@image/icon/calendar.svg';
import { ReactComponent as Moon } from '@image/icon/moon.svg';
import { ReactComponent as User } from '@image/icon/user.svg';
import { ReactComponent as Phone } from '@image/icon/phone.svg';
import { ReactComponent as Search } from '@image/icon/search.svg';
import { ReactComponent as Logo } from '@image/icon/logo.svg';
import { ReactComponent as Burger } from '@image/icon/burger.svg';
import { ReactComponent as RightArrow } from '@image/icon/arrow-right.svg';
import { ReactComponent as BigShip } from '@image/icon/big-ship.svg';
import { ReactComponent as Gadgets } from '@image/icon/gadgets.svg';
import { ReactComponent as SafetyIcon } from '@image/icon/safety-icon.svg';
import { ReactComponent as Email } from '@image/icon/email.svg';
import { ReactComponent as Twitter } from '@image/icon/twitter.svg';
import { ReactComponent as Facebook } from '@image/icon/facebook.svg';
import { ReactComponent as Pinterest } from '@image/icon/pinterest.svg';
import { ReactComponent as Instagram } from '@image/icon/instagram.svg';
import { ReactComponent as SliderArrowLeft } from '@image/icon/arrow-slider-left.svg';
import { ReactComponent as SliderArrowRight } from '@image/icon/arrow-slider-right.svg';

import { ReactComponent as TwitterFill } from '@image/icon/twitter-fill.svg';
import { ReactComponent as FacebookFill } from '@image/icon/facebook-fill.svg';
import { ReactComponent as PinterestFill } from '@image/icon/pinterest-fill.svg';
import { ReactComponent as InstagramFill } from '@image/icon/instagram-fill.svg';
import { ReactComponent as CheckboxMark } from '@image/icon/checkbox-check.svg';
import { ReactComponent as LocationRoad } from '@image/icon/location-road.svg';
import { ReactComponent as Filter } from '@image/icon/filter.svg';
import { ReactComponent as Percent } from '@image/icon/percent.svg';
import { ReactComponent as People } from '@image/icon/people.svg';
import { ReactComponent as Cost } from '@image/icon/cost.svg';

/* Material Design Icons */
import { ReactComponent as ChevronDown } from '@image/icon/chevron_down.svg';
import { ReactComponent as Check } from '@image/icon/check.svg';
import { ReactComponent as Clear } from '@image/icon/clear.svg';
import { ReactComponent as Includes } from '@image/icon/includes.svg';

import { ReactComponent as LoginCheck } from '@image/icon/login-check-list.svg';
import { ReactComponent as Pdf } from '@image/icon/pdf.svg';
import { ReactComponent as Tag } from '@image/icon/tag.svg';
import { ReactComponent as Blog } from '@image/icon/blog.svg';
import { ReactComponent as Globe } from '@image/icon/globe.svg';
import { ReactComponent as Delete } from '@image/icon/delete.svg';
import { ReactComponent as Sort } from '@image/icon/sort.svg';
import { ReactComponent as Question } from '@image/icon/question.svg';
import { ReactComponent as Share } from '@image/icon/share.svg';
import { ReactComponent as Printer } from '@image/icon/printer.svg';
import { ReactComponent as Fire } from '@image/icon/fire.svg';
import { ReactComponent as Ellipsis } from '@image/icon/ellipsis.svg';

export interface IconProps {
	Location: TIcon;
	Anchor: TIcon;
	Helm: TIcon;
	Ship: TIcon;
	Calendar: TIcon;
	Moon: TIcon;
	User: TIcon;
	Phone: TIcon;
	Search: TIcon;
	Logo: TIcon;
	Burger: TIcon;
	RightArrow: TIcon;
	BigShip: TIcon;
	Gadgets: TIcon;
	SafetyIcon: TIcon;
	Email: TIcon;
	Twitter: TIcon;
	Facebook: TIcon;
	Pinterest: TIcon;
	Instagram: TIcon;
	SliderArrowLeft: TIcon;
	SliderArrowRight: TIcon;
	TwitterFill: TIcon;
	FacebookFill: TIcon;
	PinterestFill: TIcon;
	InstagramFill: TIcon;
	CheckboxMark: TIcon;
	LocationRoad: TIcon;
	Filter: TIcon;
	People: TIcon;
	Percent: TIcon;
	Cost: TIcon;

	ChevronDown: TIcon;
	Check: TIcon;
	Clear: TIcon;
	Includes: TIcon;

	LoginCheck: TIcon;
	Pdf: TIcon;
	Tag: TIcon;
	Blog: TIcon;
	Globe: TIcon;
	Delete: TIcon;
	Sort: TIcon;
	Question: TIcon;
	Share: TIcon;
	Printer: TIcon;
	Fire: TIcon;
	Ellipsis: TIcon;
}

export const Icon: IconProps = {
	Location,
	Anchor,
	Helm,
	Ship,
	Calendar,
	Moon,
	User,
	Phone,
	Search,
	Logo,
	Burger,
	RightArrow,
	BigShip,
	Gadgets,
	SafetyIcon,
	Email,
	Twitter,
	Facebook,
	Pinterest,
	Instagram,
	SliderArrowLeft,
	SliderArrowRight,
	TwitterFill,
	FacebookFill,
	PinterestFill,
	InstagramFill,
	CheckboxMark,
	LocationRoad,
	Filter,
	People,
	Percent,
	Cost,

	ChevronDown,
	Check,
	Clear,
	Includes,

	LoginCheck,
	Pdf,
	Tag,
	Blog,
	Globe,
	Delete,
	Sort,
	Question,
	Share,
	Printer,
	Fire,
	Ellipsis,
};
