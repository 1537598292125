import { LocalSelectOptions } from '@app/objects/LocalSelectOptions';

export const simpleChoiceOption: Array<LocalSelectOptions> = [
	{
		text: 'Yes',
		value: Number(true),
	},
	{
		text: 'No',
		value: Number(false),
	},
];
